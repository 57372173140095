import { Inject, Injectable } from '@angular/core';
import { Observable, fromEvent, partition } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  public visibilityChange$: Observable<DocumentEventMap['visibilitychange']>;
  public pageVisible$: Observable<Event>;
  public pageHidden$: Observable<Event>;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.visibilityChange$ = fromEvent(this.document, 'visibilitychange').pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    [this.pageVisible$, this.pageHidden$] = partition(this.visibilityChange$, () => this.isVisible());
  }

  public isVisible(): boolean {
    return this.document.visibilityState === 'visible';
  }
}
