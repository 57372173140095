import { Observable, OperatorFunction } from 'rxjs';
import { NgZone } from '@angular/core';

export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return source => {
    return new Observable(observer => {
      return source.subscribe({
        next: (value: T) => zone.run(() => observer.next(value)),
        error: e => zone.run(() => observer.error(e)),
        complete: () => zone.run(() => observer.complete()),
      });
    });
  };
}
