import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  protected readonly environment = environment;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    for (const url of this.environment.preconnect) {
      // Dynamically set preconnect URL
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'preconnect');
      this.renderer.setAttribute(linkElement, 'href', url);

      // Append the link to the head of the document
      this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, linkElement);
    }
  }

  isProduction() {
    return this.environment.production;
  }
}
