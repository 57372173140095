import { DestroyRef, Inject, Injectable, isDevMode, NgZone, PLATFORM_ID } from '@angular/core';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { merge, Observable, of, OperatorFunction, share } from 'rxjs';
import { GameServerUpdateMessage, Message, TeamSpeakUpdateMessage, Topic } from '../model';
import { TeamSpeakServer as TeamSpeakServer } from '../model/teamspeak/model';
import { isPlatformBrowser } from '@angular/common';
import { SocketService } from './socket.service';
import { TriggerService } from './trigger.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GameServer } from '../model/gameserver/model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public serverUpdates$!: Observable<GameServer>;
  public teamSpeakUpdates$!: Observable<TeamSpeakServer>;

  constructor(
    zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: never,
    private readonly httpClient: HttpClient,
    private readonly destroyRef: DestroyRef,
    private socketService: SocketService,
    protected readonly triggerService: TriggerService
  ) {
    zone.runOutsideAngular(() => {
      if (isPlatformBrowser(this.platformId)) {
        const tsSocketUpdates = this.socketSource('teamspeak', x => (x as unknown as TeamSpeakUpdateMessage).server);
        const tsHttpUpdates = this.triggerService.activeScreenTimer$.pipe(this.fetchHttp<TeamSpeakServer>('teamspeak'));
        this.teamSpeakUpdates$ = this.withFallback(tsSocketUpdates, tsHttpUpdates);

        const gsSocketUpdates = this.socketSource('gameserver', x => (x as unknown as GameServerUpdateMessage).server);
        const gsHttpUpdates = this.triggerService.activeScreenTimer$.pipe(
          this.fetchHttp<GameServer[]>('gameserver'),
          map(value => value.filter(server => server !== null)),
          mergeMap(value => value)
        );
        this.serverUpdates$ = this.withFallback(gsSocketUpdates, gsHttpUpdates);
      } else {
        this.serverUpdates$ = of();
        this.teamSpeakUpdates$ = of();
      }
    });
  }

  private fetchHttp<T>(topic: Topic): OperatorFunction<void, T> {
    return source => {
      return source
        .pipe(
          concatMap(() => {
            console.log('Starting HTTP Update');
            return this.httpClient.get<T>(`${environment.endpoints.api}/${topic}`, { responseType: 'json' }).pipe(
              catchError(() => of()),
              tap({
                error: x => console.error('Update via GET failed', x),
              })
            );
          })
        )
        .pipe(tap(x => isDevMode() && console.log('Http Updates:', x)));
    };
  }

  private withFallback<T>(primary: Observable<T>, fallback: Observable<T>) {
    const toggledFallback = this.socketService.onlineStatus$.pipe(switchMap(value => (value ? of() : fallback)));
    return merge(primary, toggledFallback).pipe(takeUntilDestroyed(this.destroyRef), share());
  }
  private socketSource<T>(topic: Topic, mapper: (x: Message<unknown>) => T): Observable<T> {
    return this.socketService.getUpdateStream(topic).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(value => mapper(value)),
      tap(x => isDevMode() && console.log('WS Update:', topic, x))
    );
  }
}
