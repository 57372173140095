<div [ngClass]="{ online: server.online, offline: !server.online }" class="card border-0" data-bs-theme="light">
  <picture style="height: 100px; width: 100%; image-rendering: pixelated">
    <img
      class="card-img-top img-fluid position-relative"
      priority=""
      fill=""
      draggable="false"
      ngSrc="/assets/img/games/{{ server.type }}.webp"
      [sizes]="topImageSizes"
      alt="Artistic image for the game {{ server.type }}." />
  </picture>
  <div class="text-white cutout">
    <h2 class="card-title">{{ server.type | titlecase }}</h2>
  </div>
  <nav>
    <ul>
      <li *ngIf="server?.urls?.game">
        <a
          target="_blank"
          [href]="server!.urls!.game!"
          placement="end"
          ngbTooltip="Check out the games website"
          container="body"
          [openDelay]="1000">
          <app-icon height="1.25em" icon="{{ getGameUrlIcon(server!.urls!.game!)! }}"></app-icon>
          <div>
            <span>{{ getGameLinkText(server!.urls!.game!)! }}</span>
          </div>
        </a>
      </li>
      <li *ngIf="server?.urls?.map">
        <a
          target="_blank"
          href="{{ server!.urls!.map! }}"
          placement="end"
          ngbTooltip="Open a map of the server"
          container="body"
          [openDelay]="1000">
          <app-icon height="1.25em" icon="mdi:map"></app-icon>
          <div>
            <span>Map</span>
          </div>
        </a>
      </li>
    </ul>
  </nav>

  <div class="card-body">
    <h3 class="fs-5 card-title">{{ server.name }}</h3>
    <p class="badges">
      <span
        class="badge rounded-pill"
        [ngClass]="{
          'bg-success': server.online,
          'bg-secondary': !server.online,
        }">
        {{ server.online ? 'Online' : 'Offline' }}
      </span>
      <span class="badge rounded-pill bg-primary ms-1" *ngIf="server.online && server.players">
        {{ server.players.online }} / {{ server.players.max }}
      </span>
    </p>

    <div class="card-text" *ngIf="server.players?.list?.length || 0 > 0">
      Online Players:
      <ul>
        <li *ngFor="let player of server.players.list">{{ player }}</li>
      </ul>
    </div>

    <!--    <p class="card-text" *ngIf="server.version">-->
    <!--      <small class="text-muted">Version: {{server.version}}</small>-->
    <!--    </p>-->
  </div>
  <div class="card-footer container">
    <div class="row justify-content-between g-0">
      <div class="col">
        <small class="text-muted">Version: {{ server.version || 'Unknown' }}</small>
      </div>
      <!--      <div class="col col-auto" *ngIf="server.urls?.connect">-->
      <!--        <small class="text-muted">-->
      <!--          <a href="{{server.urls.connect}}" ngbTooltip="Connect to {{server.urls.connect}}"-->
      <!--             [openDelay]="1000">-->
      <!--            <iconify-icon height="1.25em" icon="mdi:link-variant"></iconify-icon>-->
      <!--          </a>-->
      <!--        </small>-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  </div>
</div>
