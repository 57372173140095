import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';
import { GameServer } from '../model/gameserver/model';

@Pipe({
  name: 'sortServer',
  standalone: true,
  pure: false,
})
export class SortServerPipe implements PipeTransform {
  transform<V extends GameServer>(value: V[] | null): V[] {
    return orderBy(value, ['online', 'type', 'name', 'id'], ['desc', 'asc', 'asc', 'asc']);
  }
}
