import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ServerComponent } from './server/server.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServerlistComponent } from './serverlist/serverlist.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SafeHtmlPipe } from './safe-html.pipe';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../icon/icons.module';
import { RouterModule, Routes } from '@angular/router';
import { TsViewerComponent } from './ts-viewer/ts-viewer.component';
import { SortServerPipe } from './sort-server.pipe';

const routes: Routes = [{ path: '', component: ServerlistComponent }];

@NgModule({
  declarations: [TsViewerComponent, ServerComponent, ServerlistComponent, SafeHtmlPipe],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxMasonryModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgbTooltip,
    IconsModule,
    NgOptimizedImage,
    SortServerPipe,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class HomeModule {}
