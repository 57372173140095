<div class="container d-flex flex-column min-vh-100">
  <div class="d-flex flex-column flex-grow-1">
    <header class="header align-self-start w-100 flex-grow-0 my-4">
      <h1 class="lh-base user-select-none">
        <a class="nav-link" href="#" routerLink="/">> Xyaren.de <span class="blink_me">_</span></a>
      </h1>
      <div style="position: fixed; top: 0" *ngIf="!this.isProduction()">
        <div class="d-block d-sm-none">xs <576px</div>
        <div class="d-none d-sm-block d-md-none">sm ≥576px</div>
        <div class="d-none d-md-block d-lg-none">md ≥768px</div>
        <div class="d-none d-lg-block d-xl-none">lg ≥992px</div>
        <div class="d-none d-xl-block d-xxl-none">xl ≥1200px</div>
        <div class="d-none d-xxl-block">xxl ≥1400px</div>
      </div>
    </header>
    <div class="align-self-start flex-grow-1 w-100 h-100">
      <router-outlet></router-outlet>
    </div>
    <footer
      role="contentinfo"
      class="footer row g-0 w-100 flex-grow-0 mt-4 mb-2 mb-sm-4 justify-content-between row-gap-2">
      <div class="col-12 col-sm-8 align-self-start text-center text-sm-start">
        <span id="oss-link" class="text-white-50">
          Made with
          <a
            title="Discover what this website was created with."
            routerLink="/attribution"
            routerLinkActive="active"
            class="link-secondary text-white-50"
            >open source software</a
          >.
        </span>
      </div>

      <div class="col-12 col-sm-4 text-center text-sm-end">
        <a
          title="Github"
          aria-label="Github"
          class="link-secondary"
          rel="me noopener"
          href="https://github.com/Xyaren"
          target="_blank">
          <app-icon inline height="1lh" icon="mdi:github" />
        </a>
        <a
          title="Mastodon"
          aria-label="Mastodon"
          class="link-secondary ms-2 ms-sm-0"
          rel="me noopener"
          href="https://mastodon.xyaren.de/@xyaren"
          target="_blank">
          <app-icon inline height="1lh" icon="mdi:mastodon" />
        </a>
        <a
          title="Twitter (also called X, but nobody calls it like that)"
          aria-label="Twitter (also called X, but nobody calls it like that)"
          class="link-secondary ms-2 ms-sm-0"
          rel="me noopener"
          href="https://twitter.com/TobiXyaren"
          target="_blank">
          <app-icon inline height="1lh" icon="mdi:twitter" />
        </a>
      </div>
    </footer>
  </div>
</div>
