export const environment = {
  production: true,
  refreshInterval: 30 * 1000,
  throttle: 15 * 1000,
  endpoints: {
    webSocket: 'wss://api.xyaren.de/ws',
    api: 'https://api.xyaren.de',
  },
  preconnect: ['https://api.xyaren.de'],
};
