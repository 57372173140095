import { Inject, Injectable } from '@angular/core';
import { Observable, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  public readonly online$: Observable<Event>;
  public readonly offline$: Observable<Event>;
  public readonly status$: Observable<boolean>;

  private readonly navigator: Navigator;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    const window: Window = this.document.defaultView!;
    this.online$ = fromEvent(window, 'online');
    this.offline$ = fromEvent(window, 'offline');
    this.status$ = merge(this.online$.pipe(map(() => true)), this.offline$.pipe(map(() => false)));
    this.navigator = window.navigator;
  }

  public isOnline(): boolean {
    return this.navigator.onLine;
  }
}
