import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ImageScaleBreakpointServiceService } from '../../../image-scale-breakpoint-service.service';
import { GameServer } from '../../model/gameserver/model';

@Component({
  selector: 'app-server[server]',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerComponent implements OnInit {
  @Input()
  public server!: GameServer;
  protected topImageSizes: string | undefined;

  constructor(private readonly imageScaleBreakPointService: ImageScaleBreakpointServiceService) {}

  ngOnInit(): void {
    this.topImageSizes = this.imageScaleBreakPointService.generateBootstrapBreakpoints({
      xs: 'calc(100vw -24px)',
      sm: '10rem',
      md: '13.5rem',
      lg: '18.5rem',
      xl: '17rem',
      xxl: '20rem',
    });
  }

  public getGameUrlIcon(url: string): string {
    const parsed = new URL(url);
    switch (parsed.host) {
      case 'store.steampowered.com':
        return 'custom:steam';
      default:
        return 'mdi:link-variant';
    }
  }

  getGameLinkText(url: string) {
    const parsed = new URL(url);
    return parsed.host;
  }
}
