import { GameServer } from './model/gameserver/model';
import { TeamSpeakServer } from './model/teamspeak/model';

export enum MessageType {
  Quit = 'quit',
  Error = 'error',
  Confirm = 'confirm',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  GameServerUpdate = 'gameServerUpdate',
  TeamSpeakUpdate = 'teamSpeakUpdate',
}

export interface Message<T> {
  messageType: T[keyof T];
  id: string;
  reply?: string | null;
}

export interface ErrorMessage extends Message<MessageType.Error> {
  error: string;
}

export type ConfirmMessage = Message<MessageType.Confirm>;

export type Topic = 'gameserver' | 'teamspeak';

export interface SubscribeMessage extends Message<MessageType.Subscribe> {
  topic: Topic;
}

export interface UnsubscribeMessage extends Message<MessageType.Unsubscribe> {
  topic: Topic;
}

export interface GameServerUpdateMessage extends Message<MessageType.GameServerUpdate> {
  server: GameServer;
}

export interface TeamSpeakUpdateMessage extends Message<MessageType.TeamSpeakUpdate> {
  server: TeamSpeakServer;
}
