import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
  TrackByFunction,
} from '@angular/core';
import { Subject } from 'rxjs';
import { runInZone } from '../../../utils/rxjs-zone';
import { isPlatformBrowser } from '@angular/common';
import { ImageScaleBreakpointServiceService } from '../../../image-scale-breakpoint-service.service';
import { environment } from '../../../environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import findKey from 'lodash-es/findKey';
import { Channel, Group, TeamSpeakServer, VoiceClient } from '../../model/teamspeak/model';
import { DataService } from '../data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-ts-viewer',
  templateUrl: './ts-viewer.component.html',
  styleUrls: ['./ts-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('0.2s ease-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({
          opacity: 1,
          position: 'absolute', //allows the new component to take its place
        }),
        animate('0.1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TsViewerComponent implements OnInit, AfterViewInit {
  constructor(
    protected readonly dataService: DataService,
    private readonly destroyRef: DestroyRef,
    private readonly imageScaleBreakPointService: ImageScaleBreakpointServiceService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: never
  ) {}

  ngOnInit(): void {
    this.cardTopImageSizes = this.imageScaleBreakPointService.generateBootstrapBreakpoints({
      xs: 'calc(100vw -24px)',
      sm: '32.25rem',
      md: '28.75rem',
      lg: '18.5rem',
      xl: '17rem',
      xxl: '20rem',
    });
  }

  @Output() dataChanged$ = new Subject<void>();

  protected server?: TeamSpeakServer = undefined;
  protected error: false | { status: number } = false;
  protected cardTopImageSizes: string | undefined;

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.dataService.teamSpeakUpdates$.pipe(takeUntilDestroyed(this.destroyRef), runInZone(this.zone)).subscribe({
          next: response => {
            this.error = false;
            this.server = response;
            this.dataChanged$.next();
          },
          error: err => {
            this.error = err;
            this.server = undefined;
            this.dataChanged$.next();
          },
        });
      });
    }
  }

  public isLoaded(): boolean {
    return this.server !== null && this.server !== undefined;
  }

  trackChannel: TrackByFunction<Channel> = (index, item) => {
    return item['id'];
  };
  trackGroup: TrackByFunction<Group> = (index, item) => {
    return item['name'];
  };

  clientIcon(client: VoiceClient) {
    const conditionToImageMap = {
      'client-away': (c: VoiceClient) => !!c.away,
      'output-muted': (c: VoiceClient) => !!c.outputMuted,
      'input-muted': (c: VoiceClient) => !!c.inputMuted,
      'hardware-input-muted': (c: VoiceClient) => !!c.noInputHardware,
      'hardware-output-muted': (c: VoiceClient) => !!c.noOutputHardware,
      'client-channel-commander-talking': (c: VoiceClient) => !!c.channelCommander && !!c.talking,
      'client-channel-commander': (c: VoiceClient) => !!c.channelCommander,
      'client-talking': (c: VoiceClient) => !!c.talking,
      'client-normal': () => true,
    };

    const imageName = findKey(conditionToImageMap, condition => condition(client));
    return `${imageName || 'client-normal'}.webp`;
  }

  channelIcon(channel: Channel) {
    const conditionToImageMap = {
      'channel-full': (c: Channel) =>
        c.maxClients !== null && c.maxClients !== undefined && c.maxClients <= (c.clients?.length || 0),
      'channel-password': (c: Channel) => !!c.isPasswordProtected,
      'channel-normal': () => true,
    };

    const imageName = findKey(conditionToImageMap, condition => condition(channel));
    return `${imageName || 'channel-normal'}.webp`;
  }

  getIconUrl(iconId: number) {
    if ([100, 200, 300, 500, 600].includes(iconId)) {
      return `assets/img/ts/defaultGroups/${iconId}.webp`;
    }
    return `${environment.endpoints.api}/teamspeak/icon/${iconId}`;
  }

  asClient = (something: unknown) => something as VoiceClient;
  asChannel = (something: unknown) => something as Channel;

  private common: NgxSkeletonLoaderConfigTheme = {
    height: '14px',
    'margin-bottom': 0,
  };

  channel: NgxSkeletonLoaderConfigTheme = {
    ...this.common,
    width: '14px',
  };

  user: NgxSkeletonLoaderConfigTheme = {
    ...this.common,
    width: '14px',
    'border-radius': '9px',
  };

  text(number: number): NgxSkeletonLoaderConfigTheme {
    return {
      ...this.common,
      width: `${number}rem`,
    };
  }

  getFlagIconUrl(country: string | undefined) {
    return `assets/img/ts/flags/${country?.toLowerCase()}.webp`;
  }
}
