import { CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { IconsModule } from './icon/icons.module';
import { HomeModule } from './home/home.module';

export function prefersReducedMotion(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.matchMedia('(prefers-reduced-motion)').matches;
}

export const routes: Routes = [
  {
    path: 'attribution',
    loadChildren: () =>
      import(/* webpackChunkName: "my-chunk-name" */ './attribution/attribution.module').then(m => m.AttributionModule),
  },
  {
    path: '**',
    loadChildren: () => HomeModule,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false /*isDevMode()*/,
        initialNavigation: 'enabledBlocking',
      } // <-- debugging purposes only
    ),
    BrowserAnimationsModule.withConfig({
      disableAnimations: prefersReducedMotion(),
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately', // no delay -> required for google lighthouse test
    }),
    IconsModule,
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch()),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.width) {
          const before = config.src.split('.').slice(0, -1).join('.');
          const after = config.src.substring(config.src.lastIndexOf('.') + 1, config.src.length);
          return `${before}.${config.width}px.${after}`;
        }
        return `${config.src}`;
      },
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 32,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
