import { Injectable, isDevMode } from '@angular/core';
import { VisibilityService } from '../../visibility.service';
import { OnlineService } from '../../online.service';
import { merge, Observable, repeat, share, throttleTime, timer } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TriggerService {
  public activeScreenTimer$: Observable<void>;

  constructor(
    private readonly visibilityService: VisibilityService,
    private readonly onlineService: OnlineService
  ) {
    const hiddenOrOffline$ = merge(this.visibilityService.pageHidden$, this.onlineService.offline$).pipe(
      tap(x => isDevMode() && console.log('Stopping request execution due to ', x))
    );
    const onlineAndVisible = merge(this.visibilityService.pageVisible$, this.onlineService.online$).pipe(
      tap(x => isDevMode() && console.log('Continue trigger ', x)),
      filter(() => this.onlineService.isOnline() && this.visibilityService.isVisible()),
      tap(x => isDevMode() && console.log('Continuing due to ', x))
    );

    this.activeScreenTimer$ = timer(0, environment.refreshInterval).pipe(
      takeUntil(hiddenOrOffline$),
      repeat({
        delay: () => onlineAndVisible,
      }),
      throttleTime(environment.throttle),
      map(() => undefined),
      tap(() => isDevMode() && console.log('Trigger')),
      share()
    );
  }
}
